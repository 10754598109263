import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

const initialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            category: {
                $containsi: '',
            },
            $or: [
                {
                    title: {
                        $containsi: '',
                    },
                },
                {
                    description: {
                        $containsi: '',
                    },
                },
            ],
        },
        pagination: {
            page: 1,
            pageSize: 5,
        },
    };
};

export default {
    namespaced: true,
    state: {
        links: [],
        query: initialQuery(),
        isDeleteLink: false,
    },
    getters: {
        getLinkList(state) {
            return state.links;
        },
        getLinkQuery(state) {
            return state.query;
        },
        getLink(state) {
            return state.link;
        },
        getIsDeleteLink(state) {
            return state.isDeleteLink;
        },
    },
    mutations: {
        setLinkList(state, links) {
            state.links = links;
        },
        setLinkQuery(state, query) {
            state.query = query;
        },
        resetLinkQuery(state) {
            state.query = initialQuery();
        },
        setLink(state, linkData) {
            state.linkData = linkData;
        },
        setLinkSearch(state, search) {
            state.query.filters.$or[0].title.$containsi = search;
            state.query.filters.$or[1].description.$containsi = search;
        },
        setIsDeleteLink(state, bol) {
            state.isDeleteLink = bol;
        },
    },
    actions: {
        fetchLinkList(ctx) {
            let _query = _.cloneDeep(ctx.state.query);

            let query = qs.stringify(_query);
            return new Promise((resolve, reject) => {
                axios
                    .get('/links?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchLink(ctx, id, query = {}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/links/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        updateLink(ctx, data) {
            let query = qs.stringify({});
            return new Promise((resolve, reject) => {
                let _data = _.assign({}, data);
                let id = _data.id;
                delete _data.id;
                axios
                    .put(`/links/${id}?${query}`, { data: _data })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addLink(ctx, linkData) {
            linkData = _.assign({}, linkData);

            return new Promise((resolve, reject) => {
                axios
                    .post('/links', { data: linkData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteLink(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/links/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteManyLinks(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/links/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        /*         
export function deleteManyItems(query = {}) {
    query = qs.stringify(query);
    return axios.delete(`/items/many?${query}`);
} */
        /*   addSections(ctx, sectionsData) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/parking-lots-sections', { data: sectionsData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        }, */
    },
};
